export const millisecondsInDay = 24 * 3600 * 1000;

export const getWeekStart = (date) => {
  const timestamp = date.getTime();
  const dayNumber = date.getDay();
  return new Date(timestamp - dayNumber * millisecondsInDay);
};
export const utcTimeZoneString = 'UTC';

export const toUsDateFormat = (date, timeZone) => {
  return new Intl.DateTimeFormat('en-US', {
    timeZone: timeZone, year: 'numeric', month: 'numeric', day: 'numeric'
  }).format(date);
};

export const toUsDateTimeFormat = (date) => {
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'UTC', year: 'numeric', month: 'numeric', day: 'numeric',
    hour: 'numeric', minute: 'numeric', second: 'numeric'
  }).format(date);
};

export const parseISOLocal = (s) => {
  if (!s) {
    return;
  }
  var b = s.split(/\D/);
  return new Date(b[0], b[1] - 1, b[2], b[3] || 0, b[4] || 0, b[5] || 0);
};

export const getISODateString = (date) => {
  const year = date.getFullYear();
  const monthNumber = date.getMonth() + 1;
  const dayNumber = date.getDate();
  const month = monthNumber > 9 ? monthNumber.toString() : `0${monthNumber}`;
  const day = dayNumber > 9 ? dayNumber.toString() : `0${dayNumber}`;
  return `${year}-${month}-${day}`;
};

export const getWeekDates = (weekStartDate) => {
  const timestamp = weekStartDate.getTime();
  const result = [];
  for (let i = 0; i < 7; i++) {
    result.push(new Date(timestamp + i * millisecondsInDay));
  }
  return result;
};

export const getDiffDays = (date1Str, date2Str) => {
  return (new Date(date1Str).getTime() - new Date(date2Str).getTime())/millisecondsInDay;
};
